import React from 'react';
import Helmet from 'react-helmet';
import { Content, Header, Layout, SectionTitle } from '../components';

import SiteConfig from '../../config/SiteConfig';
import { HeaderItem } from '../components/Header';
import { Link } from 'gatsby';
import styled from 'styled-components';

const ClearP = styled.p`
    clear: left;
`;

const SwickContent = styled(Content)`
    background-color: #ffa169;
    line-height: 1.4rem;
    margin: -24px;
    padding: 24px;

    & p:first-child {
        margin-top: 0;
    }

    a {
        text-decoration: underline;
    }
`;

const SubmitArea = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 24px;
`;

const SubmitButton = styled.button`
    padding: 12px;
    font-weight: 600;
`;

const SubmitError = styled.div`
    flex-grow: 1;
    color: #810303;
    padding: 12px;
    font-weight: 600;
`;

const SubmitMessage = styled.div`
    flex-grow: 1;
    padding: 12px;
    font-weight: 600;
`;

const NiceForm = styled.div`
    // Input size - used for height/padding calculations
    --nf-input-size: 1rem;

    --nf-input-font-size: calc(var(--nf-input-size) * 0.875);
    --nf-small-font-size: calc(var(--nf-input-size) * 0.875);

    // Font family
    --nf-input-font-family: inherit;
    --nf-label-font-family: inherit;

    // Input
    --nf-input-color: #20242f;
    --nf-input-border-radius: 0.25rem;
    --nf-input-placeholder-color: #929292;
    --nf-input-border-color: #c0c4c9;
    --nf-input-border-width: 1px;
    --nf-input-border-style: solid;
    --nf-input-border-bottom-width: 2px;
    --nf-input-focus-border-color: #b64f11;
    --nf-input-background-color: #f9fafb;

    // Valid/invalid
    --nf-invalid-input-border-color: var(--nf-input-border-color);
    --nf-invalid-input-background-color: var(--nf-input-background-color);
    --nf-invalid-input-color: var(--nf-input-color);
    --nf-valid-input-border-color: var(--nf-input-border-color);
    --nf-valid-input-background-color: var(--nf-input-background-color);
    --nf-valid-input-color: inherit;
    --nf-invalid-input-border-bottom-color: #d00101;
    --nf-valid-input-border-bottom-color: #f1813c;

    // Label variables
    --nf-label-font-size: var(--nf-small-font-size);
    --nf-label-color: #374151;
    --nf-label-font-weight: 600;

    // Slider variables
    --nf-slider-track-background: #dfdfdf;
    --nf-slider-track-height: 0.25rem;
    --nf-slider-thumb-size: calc(var(--nf-slider-track-height) * 4);
    --nf-slider-track-border-radius: var(--nf-slider-track-height);
    --nf-slider-thumb-border-width: 2px;
    --nf-slider-thumb-border-focus-width: 1px;
    --nf-slider-thumb-border-color: #ffffff;
    --nf-slider-thumb-background: var(--nf-input-focus-border-color);

    display: block;
    margin-top: calc(var(--nf-input-size) * 1.5);
    line-height: 1;
    white-space: nowrap;

    // ------------- LABEL

    > label {
        font-weight: var(--nf-label-font-weight);
        display: block;
        color: var(--nf-label-color);
        font-size: var(--nf-label-font-size);
        font-family: var(--nf-label-font-family);
        margin-bottom: calc(var(--nf-input-size) / 2);
        white-space: initial;

        + small {
            font-style: initial;
        }
    }

    // ------------- SMALL

    small {
        font-family: var(--nf-input-font-family);
        display: block;
        font-weight: normal;
        opacity: 0.75;
        font-size: var(--nf-small-font-size);
        margin-bottom: calc(var(--nf-input-size) * 0.75);

        &:last-child {
            margin-bottom: 0;
        }
    }

    // ------------- LEGEND

    > legend {
        font-weight: var(--nf-label-font-weight);
        display: block;
        color: var(--nf-label-color);
        font-size: var(--nf-label-font-size);
        font-family: var(--nf-label-font-family);
        margin-bottom: calc(var(--nf-input-size) / 5);
    }

    > .nice-form-group {
        margin-top: calc(var(--nf-input-size) / 2);
    }

    > input[type="text"],
    > input[type="checkbox"],
    > input[type="radio"],
    > input[type="email"],
    > input[type="tel"],
    > input[type="url"],
    > input[type="password"],
    > input[type="number"],
    > input[type="date"],
    > input[type="week"],
    > input[type="time"],
    > input[type="month"],
    > input[type="search"],
    > textarea,
    > select {
        background: var(--nf-input-background-color);
        font-family: inherit;
        font-size: var(--nf-input-font-size);
        border-color: var(--nf-input-border-color);
        border-width: var(--nf-input-border-width);
        border-style: var(--nf-input-border-style);
        font-family: var(--nf-input-font-family);
        box-shadow: none;
        border-radius: var(--nf-input-border-radius);
        border-bottom-width: var(--nf-input-border-bottom-width);
        color: var(--nf-input-color);
        width: 94%;
        padding: calc(var(--nf-input-size) * 0.75);
        height: calc(var(--nf-input-size) * 1.25);
        line-height: normal;
        appearance: none;
        transition: all 0.15s ease-out;

        // ------------- VALID/INVALID

        &:required {
            &:not(:placeholder-shown):invalid {
                background-color: var(--nf-invalid-input-background-color);
                border-color: var(--nf-valid-input-border-color);
                border-bottom-color: var(--nf-invalid-input-border-bottom-color);
                color: var(--nf-invalid-input-color);

                // Reset to default when focus

                &:focus {
                    background-color: var(--nf-input-background-color);
                    border-color: var(--nf-input-border-color);
                    color: var(--nf-input-color);
                }
            }

            &:not(:placeholder-shown):valid {
                background-color: var(--nf-valid-input-background-color);
                border-color: var(--nf-valid-input-border-color);
                border-bottom-color: var(--nf-valid-input-border-bottom-color);
                color: var(--nf-valid-input-color);
            }
        }

        // ------------- DISABLED

        &:disabled {
            cursor: not-allowed;
            opacity: 0.75;
        }

        // -------- PLACEHOLDERS

        &::-webkit-input-placeholder {
            color: var(--nf-input-placeholder-color);
            letter-spacing: 0;
        }

        &:-ms-input-placeholder {
            color: var(--nf-input-placeholder-color);
            letter-spacing: 0;
        }

        &::-moz-placeholder {
            color: var(--nf-input-placeholder-color);
            letter-spacing: 0;
        }

        &:-moz-placeholder {
            color: var(--nf-input-placeholder-color);
            letter-spacing: 0;
        }

        // -------- FOCUS

        &:focus {
            outline: none;
            border-color: var(--nf-input-focus-border-color);
        }

        // -------- ADDITIONAL TEXT BENEATH INPUT FIELDS

        + small {
            margin-top: 0.5rem;
        }

        // -------- ICONS

        --icon-padding: calc(var(--nf-input-size) * 2.25);
        --icon-background-offset: calc(var(--nf-input-size) * 0.75);

        &.icon-left {
            background-position: left var(--icon-background-offset) bottom 50%;
            padding-left: var(--icon-padding);
            background-size: var(--nf-input-size);
        }

        &.icon-right {
            background-position: right var(--icon-background-offset) bottom 50%;
            padding-right: var(--icon-padding);
            background-size: var(--nf-input-size);
        }

        // When a field has a icon and is autofilled, the background image is removed
        // by the browser. To negate this we reset the padding, not great but okay

        &:-webkit-autofill {
            padding: calc(var(--nf-input-size) * 0.75) !important;
        }
    }


    // -------- EMAIL

    > input[type="email"][class^="icon"] {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%236B7280'  stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-at-sign'%3E%3Ccircle cx='12' cy='12' r='4'/%3E%3Cpath d='M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
    }


    // -------- COLOR

    > input[type="color"] {
        border: var(--nf-input-border-width) solid var(--nf-input-border-color);
        border-bottom-width: var(--nf-input-border-bottom-width);
        height: calc(var(--nf-input-size) * 2);
        border-radius: var(--nf-input-border-radius);
        padding: calc(var(--nf-input-border-width) * 2);

        &:focus {
            outline: none;
            border-color: var(--nf-input-focus-border-color);
        }

        &::-webkit-color-swatch-wrapper {
            padding: 5%;
        }

        @mixin swatch {
            border-radius: calc(var(--nf-input-border-radius) / 2);
            border: none;
        }

        &::-moz-color-swatch {
            @include swatch;
        }

        &::-webkit-color-swatch {
            @include swatch;
        }
    }


`;


export default function swickProjectPage() {
//  const [value, setValue] = React.useState<FormObject>({});
  const [name, setName] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [serverResponse, setServerResponse] = React.useState('');
  const [serverError, setServerError] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);

  function handleName(event: React.ChangeEvent<HTMLInputElement>) {
    setServerResponse('');
    setServerError(false);
    setSubmitted(false);
    setName(event.target.value);
  }

  function handleEmail(event: React.ChangeEvent<HTMLInputElement>) {
    setServerResponse('');
    setServerError(false);
    setSubmitted(false);
    setEmail(event.target.value);
  }

  // When the form is submitted, send the form values
  // to our function for processing.
  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setSubmitted(true);
    setServerError(false);
    setServerResponse('Please wait...');
    const response = await window
      .fetch('/.netlify/functions/sendSubscriptionEmail', {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(
          {
            subscriberName: name,
            subscriberEmail: email,
          },
        ),
      });

    console.info(`Type: ${response.type}`);
    if (response.status === 404 || response.status >= 500) {
      setServerError(true);
      setServerResponse(`${response.status}: Unable to sign up at the moment. Try again later I suppose?`);
    } else {
      setServerError(false);
      setServerResponse(`${await response.text()}`);
    }
  }

  return (
    <Layout swick={true}>
      <Helmet title={`Contact | ${SiteConfig.siteTitle}`}/>
      <Header linkToHomepage={true} selected={HeaderItem.Swick}>
        <SectionTitle>My next project</SectionTitle>
      </Header>
      <SwickContent>
        <p><b>May 2024:</b> I'm soon starting an online guide to Melbourne. I have not established
          exactly what I am making yet, but I know that I want an alternative to the monoculture of
          big tech that has become the gatekeeper of local community information.</p>
        <p>Some of you have told me that you want me to keep you updated! Sign up below and I'll
          email you when there's something to show. Don't expect to hear much for a while... I
          expect to spend a couple of months exploring ideas before I'll be on course.</p>
        <h2>Sign up for email news</h2>
        <div className="subscribe-form-container">
          <form onSubmit={handleSubmit} method="POST">
            <NiceForm>
              <label htmlFor="name">Name</label>
              <input type="text" id="name" name="name" required={true} value={name}
                     placeholder="Your name..." onChange={handleName}/>
            </NiceForm>
            <NiceForm>
              <label htmlFor="email">Email</label>
              <input type="email" id="email" name="email" required={true} value={email}
                     placeholder="Your email address..."
                     onChange={handleEmail}/>
            </NiceForm>
            <SubmitArea>
              <SubmitButton type="submit" disabled={submitted}>Subscribe</SubmitButton>
              {serverResponse && (serverError && (
                <SubmitError>Error {serverResponse}</SubmitError>
              ) || (
                <SubmitMessage>{serverResponse}</SubmitMessage>
              ))}
            </SubmitArea>
          </form>
        </div>
        <ClearP><br/><Link to="/">Return to the homepage</Link></ClearP>
      </SwickContent>
    </Layout>
  );
}
